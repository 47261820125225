.item {
    cursor: pointer;
    border: 1px solid #ededed;
    border-top: 0;
    padding: 0.5em 0.75em;
}

.item:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.item:first-child {
    border: 1px solid #ededed;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.item:hover {
    background: rgb(245, 245, 245);
}

.heading {
    display: flex;
    justify-content: space-between;
}

.clearBtn {
    font-size: small;
}