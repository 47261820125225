.fullscreen {
    max-height: 100vh;
    padding: 16px;
    width: auto;
    background: white;
}

.modal {
    width: 100%;
    max-width: 1000px;
}
