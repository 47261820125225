.client {
    position: absolute;
    padding-top: 10px;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: inherit;
    gap: 8px;
}

.name {
    color: white;
    text-shadow: 2px 2px 4px black;
    font-size: 1.2em;
}

.button {
    width: 58px;
    height: 40px;
}