.video {
    position: relative;
    width: 100%;
    background: black;
    overflow: hidden;
}

#footer {
    position: fixed;
    background: #000010a3;
    left: 0;
    right: 0;
    bottom: 0;
    height: 60px;
    z-index: 2;
    margin: 0;
}
